
.menu--horizontal
{
    line-height: 1.5rem;
    text-align: center;

    ul {
        margin: 0;
        padding:  0;
    }

    li
    {
        display: inline-block;
        position: relative;
        vertical-align: top;

        a
        {
            position: relative;
            display: inline-block;
            padding: 2.4rem $indent-s;
            color: $color-grey-80;
            text-decoration: none;
            z-index: 2;
            transition: none; //Remove transition to prevent submenu's from jumping

            &.is-loading
            {
                padding-left: $indent-xl;

                &:before
                {
                    right: -$indent-xxxl;
                    top: -$indent-s;
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            > a {
                border-bottom: 2px solid var(--color-theme);
            }
        }

        .link--active
        {
            border-bottom: 2px solid var(--color-theme);
        }

        &.menu__log-out
        {
            font-size: $font-size-default;

            a {
                padding-right: 2.4rem;
                padding-left: 2.4rem;
                background-color: var(--color-theme);
                border-bottom: 1px solid var(--color-theme);
                color: $color-default;

                &:active,
                &:hover,
                &:focus
                {
                    background-color: var(--color-theme);
                    border-bottom: 1px solid var(--color-theme);
                }

                &.is-loading {
                    padding-left: calc(2.4rem + 3rem);

                    &:after {
                        top: -$indent-xxs;
                        left: $indent-s;
                    }
                }
            }
        }

        &.menu__onboarding {
            a {
                padding: 2.1rem $indent-s $indent-m;
            }
        }

        .link--submenu
        {
            display: none;
            position: absolute;
            left: -$indent-m;
            right: -$indent-m;
            margin-top: 0;
            top: 100%;
            z-index: 998;

            ul
            {
                margin: 0;
                padding: 0;
                background-color: $color-default;
                border-radius: $border-radius;
                width: 100%;

                @include mi-drop-shadow-color(0, 1px, 3px, 0, $color-grey-40);
            }

            li
            {
                display: block;
                width: 100%;

                a
                {
                    display: block;
                    background: $color-default;
                    margin: 0;
                    padding: $indent-base 0;

                    &.link--active,
                    &:hover
                    {
                        border: none;
                        background-color: $color-grey-10;
                    }
                }
            }
        }

        &:hover .link--submenu
        {
            display: block;
        }

        &#menu-path-talentpool {
            a {
                margin-right: $indent-m;
            }
            
            .new-label {
                pointer-events: none;
                border-radius: $border-radius-l;
                padding: $indent-action;
                background-color: $color-theme;
                position: absolute;
                top: $indent-xs;
                right: 0;
                font-size: $indent-xs;
                color: $color-default;
            }
        }
    }


    .flexapp__logo {
        a {
            padding-top: $indent-s;
            padding-bottom: 0.8rem;
        }
        img {
            height: 36px;
        }
    }
}

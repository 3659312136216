.talentpool-organisations {
  .required {
    color: $color-alert;
  }

  .button__container {
    display: flex;
    justify-content: center;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  /* Togle */
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-grey-20;
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: $color-default;
    -webkit-transition: .2s;
    transition: .2s;
  }
  
  input:checked + .slider {
    background-color: $color-cta;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $color-cta;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .switch-text {
    margin-left: $indent-xs;
    line-height: 100%;
    vertical-align: text-bottom;
  }

  .expand-container {
    display: flex;
    justify-content: center;
    z-index: 10;
    cursor: pointer;

    .expand-button {
      .icons {
        margin-right: $indent-m;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .talentpool-organisations {
    .button--cta {
      width: auto;
    }
  }

  .hide-md {
    display: none;
  }
}

.header-buttons {
  display: flex;
  justify-content: end;
  gap: $indent-l;

  .remove-button {
    color: var(--color-cta);
    align-content: center;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.leave-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: $indent-l;
  margin-top: $indent-hor;

  .button--secondary {
    @extend .button;
  
    background: none;
    color: $color-alert;
    border: 1px solid $color-alert;
    box-shadow: none;
    width: 50%;
  }
}

@media (max-width: $screen-md) {
  .leave-button-container {
    margin-top: $indent-m;

    .button--secondary {
      width: 100%;
    }
  }

  .header-buttons {
    display: none;
  }
}